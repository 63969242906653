<template>
  <v-container class="mt-n5" fluid>
    <v-row>
      <v-col>
        <v-card color="white" class="rounded-l py-4" flat>
          <div class="px-4 d-flex justify-space-between">
            <div class="pt-1">Recordings received</div>
            <div>
              <v-autocomplete
                solo
                flat
                dense
                rounded
                background-color="secondary"
                append-icon=""
                :items="receivedOptions"
                v-model="received_recording.duration"
                style="width: 250px"
                @change="getDonutSeries('received_recording')"
              >
                <template v-slot:append>
                  <v-icon class="rounded-xl white">mdi-chevron-down</v-icon>
                </template>
              </v-autocomplete>
            </div>
          </div>
          <apex-chart
            width="500"
            height="245"
            type="area"
            :options="lineOptions"
            :series="lineSeries"
          ></apex-chart>
        </v-card>
      </v-col>
      <v-col>
        <v-card color="white" class="rounded-l py-4" flat>
          <div class="px-4 d-flex justify-space-between">
            <div class="pt-1">Recordings by Label</div>
            <div>
              <v-autocomplete
                solo
                flat
                dense
                rounded
                background-color="secondary"
                append-icon=""
                :items="categoryOptions"
                v-model="my_recording.duration"
                style="width: 250px"
                @change="getDonutSeries('my_recording')"
              >
                <template v-slot:append>
                  <v-icon class="rounded-xl white">mdi-chevron-down</v-icon>
                </template>
              </v-autocomplete>
            </div>
          </div>
          <div class="d-flex justify-center">
            <span class="label-position">Total Recordings</span>
            <span class="total-position">{{ total_recordings }}</span>
            <apex-chart
              width="350"
              type="donut"
              :options="donutOptions"
              :series="donutSeries"
            ></apex-chart>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <div class="mt-4">
      <MyLibrary />
    </div>
  </v-container>
</template>

<script>
import MyLibrary from "../../components/MyLibrary";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Dashboard",
  components: {
    MyLibrary,
  },
  data() {
    return {
      lineOptions: {
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 3,
          colors: ["#8faae0"],
        },
        chart: {
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        colors: ["#8faae0"],
        fill: {
          gradient: {
            enabled: true,
            opacityFrom: 0.9,
            opacityTo: 0,
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          tickAmount: 2,
          min: 0,
          max: 0,
        },
        markers: {
          size: 4,
        },
      },
      lineSeries: [
        {
          data: [0, 10, 65, 12, 25, 80, 40],
        },
      ],
      donutOptions: {
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: "bottom",
          width: "370",
        },
        labels: [],
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            expandOnClick: false,
            offsetX: 0,
            offsetY: 0,
            customScale: 1,
            donut: {
              size: "85%",
            },
          },
        },
      },
      donutSeries: [0, 0, 0, 0, 0],
      categoryOptions: [
        { text: "This week", value: "this_week" },
        { text: "Last week", value: "last_week" },
        { text: "All", value: "all" },
      ],
      receivedOptions: [
        { text: "This week", value: "this_week" },
        { text: "Last week", value: "last_week" },
      ],
      my_recording: {
        duration: "this_week",
      },
      received_recording: {
        duration: "this_week",
      },
    };
  },
  created() {
    this.checkUserStatus();
    this.getDonutSeries();
    this.$root.$on("reload-all-recordings", async () => {
      // await this.$refs.my_recording.myRecordings();
      await this.$refs.received_recording.receivedRecordings();
    });
    // this.setDefaultDomainSettings();
  },
  computed: {
    ...mapGetters("profile", ["user"]),
    total_recordings() {
      return this.donutSeries.reduce((a, b) => a + b);
    },
  },
  methods: {
    ...mapActions("users", ["user_details", "get_account_details"]),
    ...mapActions("domain", ["get_domains"]),
    async checkUserStatus() {
      try {
        const userId = this.user.id;
        const response = await this.get_account_details(userId);
        const user = response;

        this.$store.commit("auth/USER_DATA", user);
        window.localStorage.setItem("user", JSON.stringify(user));

        if (user && user.status === "suspended") {
          this.$store.commit("auth/LOGOUT");
          this.$router.replace({ name: "Login" });
        }
      } catch (e) {
        console.error("Error checking user status:", e);
      }
    },
    async getDonutSeries(type) {
      try {
        const data = {
          duration:
            type === "my_recording"
              ? this.my_recording.duration
              : type === "received_recording"
              ? this.received_recording.duration
              : null,
          type: type,
        };
        const response = await this.user_details(data);
        let max = response.data.line_series;
        let labels = response.data.count_categories.map((x) => x.name);
        let series = response.data.count_categories.map((x) => x.count);
        if (labels.length < 1) {
          labels = [""];
          series = [0];
        }
        if (labels.length > 5) {
          labels = labels.slice(0, 4);
          series = series.slice(0, 4);
          labels.push("Others...");
        }
        this.donutOptions = {
          ...this.donutOptions,
          ...{
            labels: labels,
          },
        };
        this.donutSeries = series;
        this.lineSeries = [{ data: response.data.line_series }];
        this.lineOptions = {
          ...this.lineOptions,
          ...{
            xaxis: {
              categories: response.data["x-axis"],
            },
          },
        };
        this.lineOptions = {
          ...this.lineOptions,
          ...{
            yaxis: {
              tickAmount: Math.max(...max) + 1,
              min: 0,
              max: Math.max(...max) + 1,
            },
          },
        };
      } catch (e) {
        console.log(e);
      }
    },
    // async setDefaultDomainSettings() {
    //   await this.get_domains();
    // },
    // async checkExtensionInstall() {
    //   if (
    //     !window.localStorage.getItem("extension") &&
    //     !window.localStorage.getItem("skip_extension")
    //   ) {
    //     // await this.$router.replace({ name: "Onboarding" });
    //   }
    // },
  },
  beforeRouteEnter(to, from, next) {
    if (from.path === "/sign_in" || from.path === "/select-account") {
      if (
        !window.localStorage.getItem("extension") &&
        !window.localStorage.getItem("skip_extension")
      ) {
        next({
          path: "/onboarding",
        });
      } else {
        next();
      }
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.total-position {
  position: absolute;
  top: 45%;
  font-weight: 600;
  font-size: 30px;
}
.label-position {
  position: absolute;
  top: 55%;
}
</style>
